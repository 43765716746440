<template>
  <v-container>
    <v-row class="text-center" >
      <v-col cols="12" md="4" sm="6" class="d-flex flex-column" v-for="(product, index) in products" :key="index">
        <v-hover v-slot="{ hover }">
          <v-card
            class="mx-auto flex d-flex flex-column"
            :href="product.link" 
            :img="require('../assets/bg.jpg')"
            target="_blank"
          >
            <v-img
              :src="product.image"
              :class="hover ? 'zoom' : ''"
              alt="'Dose ' + product.name"
            ></v-img>

            <v-spacer></v-spacer>

            <v-card-title class="justify-center flex " >
              {{product.name}}
            </v-card-title>

            <v-spacer></v-spacer>

            <v-card-subtitle class="justify-center flex">
              {{product.subText}}
            </v-card-subtitle>

            <v-spacer></v-spacer>

            <v-card-actions class="justify-center flex">
              <v-btn>
                Im Shop für nur 2,59 €
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ProductList',

    data: () => ({
      products: [
        {
          name: 'Erbseneintopf',
          image: require('../assets/erbseneintopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/erbseneintopf-1200ml',
          subText: `Sehr schmackhaft, klassisch zubereiteter und tafelfertiger Erbseneintopf.
                    Mit geräuchertem Schweinefleisch, Kartoffelstückchen, Karotten und Zwiebeln.`
        },
        {
          name: 'Gräupchentopf',
          image: require('../assets/graeupchentopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/graupchentopf-1200ml-1',
          subText: `Leckere Spezialität vom Fleischermeister für alle, die es etwas deftig mögen.
                    Feinste Perlgraupen mit geräuchertem Schweinefleisch, mit Karotten, Zwiebeln, 
                    Porree sowie Kräutern und Gewürzen ergeben diesen schmackhaften Eintopf.`
        },
        {
          name: 'Grüner Bohneneintopf',
          image: require('../assets/gruenerbohneneintopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/grune-bohnen-eintopf-1200ml-1',
          subText: `Eine Spezialität vom Fleischermeister die satt und glücklich macht.
                    Grüne Bohnentopf mit geräuchertem Fleisch, angereichert mit kleinen 
                    Kartoffelstückchen sowie zarten Karotten- und Zwiebelstückchen, mit Kräutern gewürzt.`
        },
        {
          name: 'Hühner-Reiseintopf',
          image: require('../assets/huehnerresieintopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/huhnerreistopf-1200ml-1',
          subText: `Unser hausgemachter Eintopf, eine Spezialität vom Fleischermeister einfach bestellen und wohlfühlen.
                    Viel Gemüse wie Karotten, Kohlrabi, Blumenkohl Erbsen und Porree schonend zubereitet mit einer Portion Hühnerfleisch
                    und sorgfältig ausgewähltem Reis ergeben diesen schmackhaften Hühnerreistopf.`
        },
        {
          name: 'Kartoffelsuppe',
          image: require('../assets/kartoffelsuppe.webp'),
          link: 'https://www.lausitzer-shop.de/products/kartoffelsuppe-1200ml-1',
          subText: `Ein heißgeliebter Klassiker unsere Kartoffelsuppe schmeckt deftig und wärmt so richtig schön auf.

Eine herzhafte Kartoffelsuppe mit viel Kartoffeln, geschmacklich abgerundet mit Karotten, Erbsen, Sellerie, Porree und Zwiebel sowie geräuchertes Schweinefleisch.`
        },
        {
          name: 'Linseneintopf',
          image: require('../assets/linseneintopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/linseneintopf-1200ml-1',
          subText: `Dieser leckere Linseneintopf schmeckt wie hausgemacht! Einfach und schnell haben Sie eine leckere und herzhafte Mahlzeit zubereitet.

Die Suppentradition mit Linsen schmeckt wie hausgemacht.
Dieser herzhafte Linseneintopf mit geräuchertem Fleisch, Kartoffeln und Karotten`
        },
        {
          name: 'Mexikanischer Bohneneintopf',
          image: require('../assets/mexikanischerbohneneintopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/mexikanischer-bohneneintopf-1200ml-1',
          subText: `Super leckerer, leicht feuriger mexikanischer Bohneneintopf.

Typisch Mexikanischer Bohnentopf mit roten und weißen Bohnen, verfeinert mit Fleischwurst,
Karotten, Brechbohnen, Zwiebeln und Paprika leicht feurig zubereitet.`
        },
        {
          name: 'Pichelsteinertopf',
          image: require('../assets/pichelsteinertopf.webp'),
          link: 'https://www.lausitzer-shop.de/products/pichelsteiner-eintopf-1200ml-1',
          subText: `Der Pichelsteiner bietet eine köstliche, bunte Gemüsemischung aus Weißkohl, Kartoffeln, Karotten, Porree und Brechbohnen, verfeinert mit geräuchertem Schweinefleisch.`
        },
        {
          name: 'Soljanka',
          image: require('../assets/soljanka.webp'),
          link: 'https://www.lausitzer-shop.de/products/soljanka-1200ml-1',
          subText: `Klassische Soljanka mit zahlreichen Zutaten wie Weißkraut, Gurken, Karotten, Fleischwurst, Zwiebeln, Paprika, und Sauerkraut verfeinert mit Sauerrahm.
Zubereitet nach traditionellem Rezept.
Für ein einzigartiges Geschmackserlebnis besonders reichhaltig, sättigend und ideal für eine vollwertige Mahlzeit geeignet.`
        }
      ]
    }),
  }
</script>
<style lang="scss">
  .v-image {.v-image__image { transition: all 0.2s; } &.zoom { .v-image__image { transform: scale(1.2); } } }

  .v-card {
    padding:1rem 0 !important;
    .v-card__title{
      font-family: "Dancing Script", sans-serif;
      font-size: 2.5rem !important;
      color: #FFF !important;
    }

    .v-card__subtitle{
      margin-top:0.25rem !important;
      color: #FFF !important;
    }
  }
</style>