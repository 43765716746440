<template>
  <v-app>
    <v-app-bar
      app
      dark
      prominent
      ref="bar"
    >
    <v-container fill-height fluid>
      <v-row align-center>
        <v-col cols="12" sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <v-img
          alt="Subbendopp Logo"
          contain
          :src="logo"
        />
      </v-col>
    </v-row>
    </v-container>
    </v-app-bar>

    <v-main>
      <ShopInfo/>
      <ProductList/>
    </v-main>

    <v-footer
      color="black"
      padless
    >
      <v-row
        no-gutters
      >
      <v-cols="3">
        

        <v-img
          alt="Subbendopp Footer Logo"
          contain
          :src="logo"
          width="200"
        />
      </v-cols="3">
      <v-col cols="3" offset="1">
        <v-list-item three-line >
          <v-list-item-content>
            <v-list-item-title class=" white--text">Lausitzer Shop</v-list-item-title>
            <v-list-item-subtitle class=" white--text">
              Altenberger Platz 5
            </v-list-item-subtitle>
            <v-list-item-subtitle class=" white--text">
              01277 Dresden
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="3" offset="2">
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-subtitle class=" white--text">
              <a href="https://www.gaumenliebe.de/impressum" target="_blank">Impressum</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class=" white--text">
              <a href="https://www.gaumenliebe.de/datenschutz" target="_blank">Datenschutz</a>
            </v-list-item-subtitle>
            <v-list-item-subtitle class=" white--text">
              <a href="https://www.gaumenliebe.de/kontakt" target="_blank">Kontakt</a>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
        <v-col
          class="black text-center white--text"
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Subbendopp</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import ShopInfo from './components/ShopInfo';
import ProductList from './components/ProductList';

export default {
  name: 'App',

  components: {
    ShopInfo,
    ProductList,
  },

  data: () => ({
    logo: require('./assets/logo.png')
  }),
};
</script>
<style lang="scss">
  #app {
    background: url('assets/bg-img.jpg');
    background-repeat: no-repeat;
      background-attachment: fixed;
      background-size: cover;
  }

  .container:not(.container--fluid) {
  max-width: 1200px;
}
</style>
