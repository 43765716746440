<template>
  <v-container>
    <v-row class="text-center" >
      <v-col cols="12" md="7" class="d-flex flex-column">
        <v-img :src="require('../assets/shop.webp')"></v-img>
      </v-col>
      <v-col cols="12" md="5" class="d-flex flex-column">
        <ul>
          <li><h1>GaumenLiebe Mittagstisch</h1></li>
          <li>ab sofort</li>
          <li>Montag - Freitag</li>
          <li>11:00 - 13:00 Uhr</li>
          <li>&nbsp;</li>
          <li>Altenberger Platz 5</li>
          <li>01277 Dresden</li>
          <li>&nbsp;</li>
          <li>täglich wechselnde Suppen aus unserem Sortiment</li>
          <li>mit Brötchen für 2,99 €</li>
          <li>pro Portion</li>
          <li>&nbsp;</li>
          <li>oder</li>
          <li>&nbsp;</li>
          <li>Bockwurst o. Wiener</li>
          <li>mit Brötchen für 1,50 €</li>
          <li>&nbsp;</li>
          <li>dazu Erfrischungsgetränke</li>
          <li>der Marke Sunju</li>
          <li>für 1,00 € je 0,5l</li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'ShopInfo',
    data: () => ({}),
  }
</script>
<style lang="scss">
  ul{
    list-style: none;
    color:#FFF;
  }
</style>